.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F5F6F7;
}

.main__title {
  margin: 80px 0 64px 104px;
  font-family: Roboto Slab, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 46px;
  color: #1A1B22;
  align-self: flex-start;
}

@media screen and (max-width: 1439px) {
  .main__title {
    margin: 40px 0 32px 40px;
    font-size: 30px;
    line-height: 34px;
  }
}

@media screen and (max-width: 767px) {
  .main__title {
    margin: 32px 0 58px 16px;
    font-size: 30px;
    line-height: 34px;
  }
}