.navigation {
  margin: 0 104px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navigation__home {
  margin: 30px auto 26px auto;
  padding: 26px 0;
  text-decoration: none;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  border-bottom: solid 3px #FFFFFF;
}

.navigation__home_type_saved {
  color: #1A1B22;
  border-bottom: solid 1px #D1D2D6;
}

.navigation__saved {
  margin: 30px auto 26px 76px;
  padding: 26px 0;
  text-decoration: none;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  border-bottom: solid 1px #D1D2D6;
}

.navigation__saved_type_saved {
  color: #1A1B22;
  border-bottom: solid 3px #1A1B22;
}

.navigation__log-button {
  margin: 0 0 0 42px;
  padding: 0;
  width: 176px;
  height: 48px;
  border-radius: 100px;
  background-color: transparent;
  border: solid 1px #FFFFFF;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.navigation__log-button_type_saved {
  border: solid 1px #1A1B22;
  color: #1A1B22;
}

.navigation__log-title {
  width: 56px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navigation__log-title_type_saved {
  color: #1A1B22;
}

.navigation__log-image {
  width: 24px;
  height: 24px;
  background-image: url('../../images/logout-white.svg');
}

.navigation__log-image_type_saved {
  background-image: url('../../images/logout.svg');
}

/*tablet/laptop view*/
@media screen and (max-width: 1439px) {
  .navigation {
    margin: 0 40px 0 0;
  }

  .navigation__home {
    margin: 22px auto 20px auto;
    padding: 20px;
    font-size: 16px;
    line-height: 24px;
  }

  .navigation__saved {
    margin: 22px auto 20px 16px;
    padding: 20px;
    font-size: 16px;
    line-height: 24px;
  }

  .navigation__log-button {
    margin: 14px 0 12px 16px;
    width: 152px;
    height: 40px;
  }

  .navigation__log-button_type_saved {
    width: 100px;
  }

  .navigation__log-title {
    font-size: 16px;
    line-height: 24px;
  }
}

/*mobile view*/
@media screen and (max-width: 767px) {
  .navigation {
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    width: 100%;
    display: none;
    flex-direction: column;
    align-items: center;
    background-color: #1A1B22FF;
    border-radius: 0 0 16px 16px;
  }

  .navigation__type_saved {
    background-color: #FFFFFFFF;
  }

  .navigation_active {
    display: flex;
  }

  .navigation__home {
    width: 288px;
    margin: 16px 0 0;
    padding: 0;
    font-size: 18px;
    line-height: 56px;
    border: none;
    /* background-color: #1A1B22FF; */
  }

  .navigation__saved {
    width: 288px;
    margin: 0 0 22px;
    padding: 0;
    font-size: 18px;
    line-height: 56px;
    border: none;
    /* background-color: #1A1B22FF; */
  }

  .navigation__log-button {
    margin: 0 0 24px;
    padding: 0;
    width: 288px;
    height: 56px;
    /* background-color: #1A1B22FF; */
  }

  .navigation__log-title {
    font-size: 18px;
    line-height: 56px;
  }
}