.search {
  width: 100%;
  margin: 80px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search__title {
  margin: 0;
  padding: 0;
  max-width: 608px;
  font-family: Roboto Slab, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 64px;
  color: #FFFFFF;
}

.search__subtitle {
  margin: 32px 0 88px;
  padding: 0;
  max-width: 608px;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
}

.search__input {
  display: flex;
  flex-direction: row;
  align-self: center;
}

.search__input-field-wrapper {
  width: 540px;
  height: 62px;
  border-radius: 100px 100px 100px 100px;
  border: 1px solid #D1D2D6;;
  background-color: #FFFFFF;
  outline: none;
  display: flex;
  align-items: center;
}

.search__input-field {
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #1A1B22;
  margin-left: 24px;
  width: 376px;
  border: none;
  background-color: #FFFFFF;
  outline: none;
} 

.search__input-field::placeholder {
  color: #B6BCBF;
}

.search__input-field:hover::placeholder {
  color: #1A1B22;
}

.search__button {
  margin-left: -100px;
  width: 168px;
  height: 64px;
  border-radius: 100px;
  background-color: #2F71E5;
  border: none;
  cursor: pointer;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
}

.search__button:hover {
  background-color: #347EFF;
}

.search__button:active {
  background-color: #2A65CC;
}

/*tablet/laptop view*/
@media screen and (max-width: 1439px) {
  .search {
    margin: 38px auto 42px;
    align-items: flex-start;
  }

  .search__title {
    margin-left: 160px;
    max-width: 385px;
    font-size: 36px;
    line-height: 40px;
  }

  .search__subtitle {
    margin: 16px 0 48px 160px;
    max-width: 452px;
  }

  .search__input-field-wrapper {
    width: 386px;
    height: 54px;
  }

  .search__input-field {
    width: 228px;
  }

  .search__button {
    width: 160px;
    height: 56px;
  }
}

/*mobile view*/
@media screen and (max-width: 767px) {
  .search {
    margin-top: 30px auto 32px;
  }

  .search__title {
    margin: auto;
    max-width: 285px;
  }

  .search__subtitle {
    margin: 16px auto 122px;
    max-width: 288px;
  }

  .search__input {
    flex-direction: column;
    justify-content: center
  }

  .search__input-field-wrapper {
    width: 288px;
    height: 56px;
    justify-content: center
  }

  .search__input-field {
    width: 256px;
    margin: 0;
  }

  .search__button {
    width: 288px;
    height: 56px;
    margin: 16px 0 0;
  }
}