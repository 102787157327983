.cards {
  width: 100%;
  background-color: #F5F6F7;
  display: none;
  flex-direction: column;
  align-items: center;
}

.cards__active {
  display: flex;
}

.cards__wrapper {
  width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cards__list {
  margin-top: 62px;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 16px;
  background-color: #F5F6F7;
}

.cards__more {
  margin: 64px auto 80px;
  width: 288px;
  height: 64px;
  background-color: #FFFFFF;
  border-radius: 100px 100px;
  border: none;
  cursor: pointer;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #1A1B22;
  display: none;
}

.cards__more:hover {
  background-color: #FFFFFFE6;
}

.cards__more_active {
  display: block;
}

/*tablet/laptop view*/
@media screen and (max-width: 1439px) {
  .cards__wrapper {
    width: 768px;
  }

  .cards__list {
    margin-top: 32px;
    gap: 8px;
  }

  .cards__more {
    margin: 32px auto 40px;
    width: 240px;
    height: 56px;
  }
}

/*mobile view*/
@media screen and (max-width: 767px) {
  .cards__wrapper {
    width: 320px;
  }

  .cards__list {
    margin-top: 58px;
    grid-template-columns: auto;
    grid-template-rows: auto;
  }

  .cards__more {
    margin: 16px auto 32px;
    width: 288px;
  }
}