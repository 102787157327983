.header-saved {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header-saved__title {
  margin: 40px auto 28px 104px;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #1A1B2280;
}

.header-saved__subtitle {
  width: 530px;
  margin: 0 0 0 104px;
  font-family: Roboto Slab, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 46px;
  color: #1A1B22;
}

.header-saved__user {
  margin: 0;
  font-family: Roboto Slab, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 46px;
  color: #1A1B22;
}

.header-saved__keywords {
  margin: 30px auto 56px 104px;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #1A1B22;
}

.header-saved__keywords-list {
  margin: 0;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #1A1B22;
}

/*tablet/laptop view*/
@media screen and (max-width: 1439px) {
  .header-saved__title {
    margin: 24px auto 16px 40px;
    font-size: 14px;
  }

  .header-saved__subtitle {
    width: 336px;
    margin: 0 0 0 40px;
    font-size: 30px;
    line-height: 34px;
  }

  .header-saved__user {
    font-size: 30px;
    line-height: 34px;
  }

  .header-saved__keywords {
    margin: 16px auto 32px 40px;
  }
}

/*mobile view*/
@media screen and (max-width: 767px) {
  .header-saved__title {
    margin: 8px auto 16px 16px;
    font-size: 12px;
  }

  .header-saved__subtitle {
    width: 288px;
    margin: 0 0 0 16px;
  }

  .header-saved__keywords {
    margin: 50px auto 32px 15px;
  }
}