@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.preloader {
  margin: auto;
  width: 50px;
  height: 50px;
  display: none;
  border: 4px solid #444;
  border-bottom-color: #888;
  border-radius: 50%;
  animation: spin .75s infinite linear;
}

.preloader_active {
  display: block; 
}