.header {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../../images/background.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.header_type_saved {
  background-image: none;
}

.header__wrapper {
  width: 1440px;
}

.header__top { 
  width: 100%;
  height: 80px;
  border-bottom: solid 1px #D1D2D6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.header__title {
  margin: 30px auto 26px 104px;
  padding: 0;
  font-family: Roboto Slab, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}

.header__title_type_saved {
  color: #1A1B22;
}

.header__button {
  display: none;
}

/*tablet/laptop view*/
@media screen and (max-width: 1439px) {
  .header {
    background-image: url('../../images/background-tablet.png');
  }

  .header_type_saved {
    background-image: none;
  }

  .header__wrapper {
    width: 768px;
  }

  .header__top {
    height: 66px;
  }

  .header__title {
    margin: 23px auto 18px 40px;
  }
}

/*mobile view*/
@media screen and (max-width: 767px) {
  .header {
    background-image: url('../../images/background-mobile.png');
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header_type_saved {
    background-image: none;
  }

  .header__wrapper {
    width: 320px;
  }

  .header__top {
    height: 56px;
  }

  .header__top_active {
    background-color: #1A1B22FF;
  }

  .header__title {
    margin: 16px 0 16px 16px;
    font-size: 16px;
    line-height: 24px;
  }

  .header__button {
    margin: 16px 16px 16px auto;
    width: 24px;
    height: 24px;
    padding: 0;
    color: #FFFFFF;
    font-size: 20px;
    line-height: 24px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inherit;
  }

  .header__button_type_saved {
    color: #000000;
  }
}