.App {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
}

.link:hover {
  opacity: 0.6;
}
