.footer {
  width: 100%;
  max-height: 80px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
}

.footer__wrapper {
  width: 1440px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.footer__title {
  margin: 32px auto 26px 104px;
  padding: 0;
  max-width: 433px;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #B6BCBF;
}

.footer__home-link {
  margin: 30px 40px 28px auto;
  padding: 0;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #1A1B22;
  text-decoration: none;
}

.footer__practicum-link {
  margin: 30px 54px 28px 0;
  padding: 0;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #1A1B22;
  text-decoration: none;
}

.footer__github {
  padding: 0;
  display: flex;
  align-items: center;
}

.footer__facebook {
  padding: 0;
  margin: 28px 104px 28px 24px;
  display: flex;
  align-items: center;
}

.footer__image {
  padding: 0;
  margin: 0;
}

/*tablet/laptop view*/
@media screen and (max-width: 1439px) {
  .footer {
    max-height: 64px;
  }

  .footer__wrapper {
    width: 768px;
  }

  .footer__title {
    margin: 24px auto 18px 40px;
    max-width: 300px;
  }
  
  .footer__home-link {
    margin: 21px 24px 21px auto;
  }
  
  .footer__practicum-link {
    margin: 21px 24px 21px 0;
  }
  
  .footer__facebook {
    margin: 20px 40px 20px 24px;
  }
}

/*mobile view*/
@media screen and (max-width: 767px) {
  .footer__wrapper {
    width: 320px;
    max-height: 176px;
    display: grid;
    grid-template-columns: repeat(2, 1fr) 24px 64px;
    grid-template-rows: 57px 37px 82px;
    grid-template-areas: 
    "home . git fb"
    "pract pract . ."
    "title title title title"
    ;
  }

  .footer__home-link {
    margin: 20px 0 38px 16px;
    grid-area: home;
  }

  .footer__github {
    grid-area: git;
  }
  
  .footer__facebook {
    grid-area: fb;
  }
  .footer__practicum-link {
    margin: 0 0 0 16px;
    align-self: center;
    grid-area: pract;
  }

  .footer__title {
    margin: 38px auto 22px;
    grid-area: title;
  }
}
