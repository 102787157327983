.card {
  display: grid;
  grid-template-columns: 400px;
  grid-template-rows: 272px 56px 76px 128px 44px;
  border-radius: 16px;
  background-color: #FFFFFF;
  position: relative;
}

.card__button {
  padding: 0;
  margin: 0;
  width: 40px;
  height: 40px;
  background-image: url('../../images/save-button.svg');
  border-radius: 8px;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 24px;
  right: 24px;
}

.card__button:hover {
  background-image: url('../../images/save-button-hover.svg');
}

.card__button_type_saved {
  background-image: url('../../images/trashcan.svg');
}

.card__button_type_saved:hover {
  background-image: url('../../images/trashcan-hover.svg');
}

.card__button_active {
  background-image: url('../../images/save-button-selected.svg');
}

.card__button_active:hover {
  background-image: url('../../images/save-button-selected.svg');
}

.card__message {
  margin: 0;
  padding: 0;
  width: 159px;
  height: 40px;
  background-color: #FFFFFF;
  border-radius: 10px;
  position: absolute;
  top: 24px;
  right: 69px;
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  display: none;
  align-items: center;
  justify-content: center;
  visibility: hidden;
}

.card__button:hover ~ .card__message {
  visibility: visible;
  transition: opacity 0.7s linear, visibility 0s linear 0.2s;
}

.card__message_active {
  display: flex;;
}

.card__keyword {
  margin: 0;
  padding: 0 22px;
  height: 40px;
  background-color: #FFFFFF;
  border-radius: 10px;
  position: absolute;
  top: 24px;
  left: 16px;
  font-family: 'Roboto', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__image {
  width: 400px;
  height: 272px;
  background-size: contain;
  border-radius: 16px 16px 0 0;
}

.card__date {
  margin: 20px auto 12px 24px;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #B6BCBF;
}

.card__title {
  width: 352px;
  margin: auto 0 auto 24px;
  font-family: Roboto Slab, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  color: #1A1B22;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.card__article {
  width: 352px;
  margin: auto;
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #1A1B22;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.card__subtitle {
  margin: 0 auto 24px 24px;
  font-family: Roboto Slab, serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #B6BCBF;
  overflow: hidden;
}

/*tablet/laptop view*/
@media screen and (max-width: 1439px) {
  .card {
    grid-template-columns: 224px;
    grid-template-rows: 150px 52px 82px 100px 36px;
  }

  .card__button {
    top: 8px;
    right: 8px;
  }

  .card__image {
    width: 224px;
    height: 150px;
  }

  .card__date {
    margin: 18px auto 10px 15px;
  }

  .card__title {
    width: 192px;
    margin-left: 16px;
    font-size: 22px;
    line-height: 30px;
    -webkit-line-clamp: 3;
  }

  .card__article {
    width: 192px;
    margin: auto;
  }

  .card__subtitle {
    margin: 0 auto 16px 16px;
  }
}

/*mobile view*/
@media screen and (max-width: 767px) {
  .card {
    grid-template-columns: 288px;
    grid-template-rows: 196px 50px 62px 96px 36px;
  }

  .card__button {
    top: 16px;
    right: 16px;
  }

  .card__image {
    width: 288px;
    height: 196px;
  }

  .card__date {
    margin: 16px auto 10px 16px;
  }

  .card__title {
    width: 256px;
    margin-left: 16px;
    font-size: 22px;
    line-height: 24px;
    -webkit-line-clamp: 2;
  }

  .card__article {
    width: 256px;
    margin: 14px auto 12px;
    -webkit-line-clamp: 3;
  }


  .card__subtitle {
    margin: 0 auto 16px 16px;
  }
}